import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import Label from 'components/Label';

// ----------------------------------------------------------------------

export type FieldDefinition = {
	id: string
	label: string
	build?: (data: any, value: any) => string | JSX.Element
	condition?: () => Boolean
	numeric?: Boolean,
	sortable?: Boolean
}

type SortingSelectingHeadProps = {
	orderBy: string;
	rowCount: number;
	numSelected: number;
	onRequestSort: (property: string) => void;
	onSelectAllClick: (checked: boolean) => void;
	order: 'asc' | 'desc';
	selectable?: boolean;
	headLabel: Array<FieldDefinition>;
};

export default function SortingSelectingHead({
	selectable = true,
	order,
	orderBy,
	rowCount,
	headLabel,
	numSelected,
	onRequestSort,
	onSelectAllClick
}: SortingSelectingHeadProps) {
	const headers = headLabel.filter(x => !x.condition || x.condition());
	return (
		<TableHead>
			<TableRow>
				{selectable && <TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							onSelectAllClick(event.target.checked)
						}
						inputProps={{
							'aria-label': 'select all desserts'
						}}
					/>
				</TableCell>}
				{headers.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'center'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{
							headCell.sortable === false ?
								<TableSortLabel
									active={false}
									hideSortIcon={true}
								>
									{headCell.label}
								</TableSortLabel>
								:
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={() => onRequestSort(headCell.id)}
								//hideSortIcon={true}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component="span" sx={{ ...visuallyHidden }}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
						}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
